import React from 'react'
import { graphql, navigate, useStaticQuery } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import cn from 'classnames'

import SEO from '../components/seo'
import Section from '../components/Common/Section'
import Container from '../components/Common/Container'
import Typography from '../components/Common/Typography'
import { Button } from '../components/Common/Button'
import RequestDemo from '../components/RequestDemo'
import ContactForm from '../components/contact-form'

import { useTranslations } from '../hooks/use-translations'

import * as s from '../pages/news.module.scss'

const RemoteInspection = () => {
  const { t, getPath } = useTranslations()
  const images = useStaticQuery(imagesQuery)
  const [modal, setModal] = React.useState(false)

  return (
    <>
      <SEO
        title={t('seo_title_remote_inspection')}
        description="Remote Inspection via 360 panoramic photos allows for comprehensive monitoring and verification of construction projects without physical presence, enabling stakeholders to make informed decisions, track progress, identify delays, ensure transparency in financial dealings, and document changes effectively through the SIGNAX  cloud-based platform."
      />
      <Section>
        <Container>
          <Typography variant="body1" color="gray" style={{ marginBottom: 8 }}>
            {t('seo_date_remote_inspection')}
          </Typography>
          <Typography variant="h1" color="blue" size={40}>
            {t('title_remote_inspection')}
          </Typography>
          <div className={cn(s.contentWrap, s.margin_top_48)}>
            <div className={s.textWrap}>
              <Typography variant="h2" size={24} mb={16}>
                {t('remote_inspection_subtitle')}
              </Typography>
              <Typography variant="body1" size={18}>
                {t('remote_inspection_text')}
              </Typography>
            </div>
            <div className="flex justify-center">
              <StaticImage
                width={564}
                src="../assets/images/solutions/remote-inspection/image-1.png"
                alt="Verify the completed scope and construction works are under remote supervision"
                title="Verify the completed scope and construction works are under remote supervision"
                placeholder="blurred"
              />
            </div>
          </div>
          <div className={s.margin_top_48}>
            <Typography variant="h2" size={24} mb={16}>
              {t('remote_inspection_subtitle2')}
            </Typography>
            <Typography variant="body1" size={18}>
              {t('remote_inspection_text2')}
            </Typography>
          </div>
          <div className={cn(s.contentWrap, s.margin_top_48)}>
            <div className={s.textWrap}>
              <Typography variant="h2" size={24} mb={16}>
                {t('remote_inspection_subtitle3')}
              </Typography>
              <Typography variant="body1" size={18}>
                {t('remote_inspection_text3')}
              </Typography>
            </div>
            <div className="flex justify-center">
              <StaticImage
                width={564}
                src="../assets/images/solutions/remote-inspection/image-2.png"
                alt="Construction progress remote monitoring and identifying any delays"
                title="Construction progress remote monitoring and identifying any delays"
                placeholder="blurred"
              />
            </div>
          </div>
          <div className={s.margin_top_48}>
            <Typography variant="h2" size={24} mb={16}>
              {t('remote_inspection_subtitle4')}
            </Typography>
            <Typography variant="body1" size={18}>
              {t('remote_inspection_text4')}
            </Typography>
          </div>
          <div className={cn(s.contentWrap, s.margin_top_48)}>
            <div className={s.textWrap}>
              <Typography variant="h2" size={24} mb={16}>
                {t('remote_inspection_subtitle5')}
              </Typography>
              <Typography variant="body1" size={18}>
                {t('remote_inspection_text5')}
              </Typography>
            </div>
            <div className="flex justify-center">
              <StaticImage
                width={564}
                src="../assets/images/solutions/remote-inspection/image-3.png"
                alt="Changes visualization"
                title="Changes visualization"
                placeholder="blurred"
              />
            </div>
          </div>
          <Button
            size="large"
            className={cn('mx-auto', s.margin_top_48)}
            onClick={() => navigate(getPath('/digital-services/'))}
          >
            {t('Other digital services')}
          </Button>
        </Container>
      </Section>
      <RequestDemo
        title={t('Know more')}
        bgImage={images.demoBg.childImageSharp.gatsbyImageData}
        moduleData={{
          btnText: t('Request a call'),
          onClick: () => setModal(true),
          btnColor: 'white',
        }}
      />
      {modal && (
        <ContactForm
          onClose={() => setModal(false)}
          title={t('Request a call')}
          requestType="Request a call"
        />
      )}
    </>
  )
}

export default RemoteInspection

const imagesQuery = graphql`
  query {
    demoBg: file(relativePath: { eq: "services-solutions-demo.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
  }
`
